import { Navigate, useRoutes } from 'react-router-dom';
import { useEffect, useState } from 'react';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
import MainLayout from '../../layouts/main';
import BetaCode from '../../layouts/Beta/BetaCode';

//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
//   const [isBeta, setIsBeta] = useState(false);

//   useEffect(() => {
//     if (localStorage.getItem('code-beta') === '420042') {
//       setIsBeta(true);
//     } else setIsBeta(false);
//   }, []);

  // if route is call console.log to see the route
//   console.log(window.location.pathname);


  return useRoutes([
    {
      path: '/',
      element: <MainLayout/>
    },

    // Auth routes
    // ...authRoutes,

    // Dashboard routes
    // ...dashboardRoutes,

    // Main routes
    // ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/" replace /> },
  ]);
}

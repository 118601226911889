import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ReactComponent as LogoSvgStart } from './logoStart.svg';
import { ReactComponent as LogoSvgEnd } from './logoEnd.svg';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
    const theme = useTheme();
    const PRIMARY_LIGHT = theme.palette.primary.light;
    const PRIMARY_DARK = theme.palette.primary.dark;
  
    const logo = (
      <Box sx={{ width: 140, height: 35,marginTop: '9px', display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx }}>
        <Box sx={{ display: 'flex', flexDirection: theme.direction === 'rtl' ? 'row-reverse' : 'row' }}>
          <Box sx={{ flex: 'none', width: '67%', height: '100%' }}>
            <LogoSvgStart
              style={{ fill: theme.palette.mode === 'light' ? '#000' : '#fff', maxWidth: '100%', height: 'auto' }}
            />
          </Box>
          <Box sx={{ flex: 'none', width: '33%', height: '100%' }}>
            <LogoSvgEnd
              style={{
                fill: theme.palette.mode === 'light' ? PRIMARY_DARK : PRIMARY_LIGHT,
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  
    if (disabledLink) {
      return <>{logo}</>;
    }
  
    return localStorage.getItem("token") !== null ? (
      <RouterLink to="/dashboard">{logo}</RouterLink>
    ) : (
      <RouterLink to="/">{logo}</RouterLink>
    );
  }
  
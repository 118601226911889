import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from 'src/auth/context/jwt/auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      authenticated: false,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);




  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.sessionStorage.getItem('accessToken');
        // console.log("1");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
        //   console.log("2");

          const response = await axios.post('/api/account/my-account');
          const { user } = response.data;
        //   console.log("3");

          dispatch({
            type: 'INITIAL',
            payload: {
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
          setSession(null);
        }
      } catch (err) {
        console.error(err);
        setSession(null);
        // toast.error('You have been logged out.');
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);






const login = useCallback(async (code) => {
    try {
      const response = await axios.post('/api/auth', { code });
      const { accessToken } = response.data;
      setSession(accessToken);
      // window.localStorage.setItem('accessToken', accessToken);
    //   console.log("accessToken success /api/auth", accessToken);
      const accountResponse = await axios.post('/api/account/my-account', null, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      //   console.log("accountResponse", accountResponse);
      const accountUser = accountResponse.data.user;
      console.log("accountUser", accountUser);

      dispatch({
        type: 'LOGIN',
        payload: {
          user: accountUser,
          loading: false,
        },
      });
    } catch (error) {
      console.error('An error occurred during login:', error);
      throw error;
      // Handle the error according to your application's needs
      // For instance, you could show a notification to the user, or log the error to an error reporting service
    }
  }, []);


  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    // console.log("logout");
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};


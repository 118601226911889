// import { lazy } from 'react';
// import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
import Auth42 from 'src/auth/hooks/Auth42';
// layouts
// import AuthClassicLayout from 'src/layouts/auth/classic';

// ----------------------------------------------------------------------

// JWT
// const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
// const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

// ----------------------------------------------------------------------

// const authJwt = {
//   path: '/',
//   element: (
//     <GuestGuard>
//       <Auth42 />
//       {/* <Outlet /> */}
//     </GuestGuard>
//   ),
// };

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Auth42/>
      </GuestGuard>
    )
  },
];

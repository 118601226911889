import { m } from 'framer-motion';
import { Close as CloseIcon } from '@mui/icons-material';
import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Button, Typography } from '@mui/material';
// @mui
import { styled, keyframes } from '@mui/material/styles';
import { Box, Modal, IconButton } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import LogoSchool from 'src/layouts/main/logo_42.svg';
import PasteIt from './paste_it.svg';
import PasteItWhite from './paste_it_white.svg';
import DiscoverIt from './discover_it.svg';
import DiscoverItWhite from './discover_it_white.svg';
import './styles/Home.css';
// routes
// components
import { MotionContainer } from '../../components/animate';
// ----------------------------------------------------------------------

const gradient = keyframes`
  0% { background-position: 0% }
  100% { background-position: 200% }
`;

const PasteItCSS = styled('img')(({ theme }) => ({
    // ... vos autres styles ...
  
    '@media (max-height:700px)': {
      display: 'none',
    },
  }));



const StyledtextSign = styled('span')(({ theme }) => ({
	color: 'white',
	fontSize: '1rem',
	fontWeight: 'bold',
	marginRight: '2px',
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
	fontSize: '90px',
	textAlign: 'center',
	fontWeight: 'bold',
	background:
		theme.palette.mode === 'light'
			? 'linear-gradient(90deg, #ff0000, #00ff00, #0000ff, #ff00ff)'
			: theme.palette.primary.light,
	backgroundRepeat: 'repeat',
	backgroundSize: '200% 100%',
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	animation: `${gradient} 5s ease infinite`,
}));

const StyledLogo = styled('img')(({ theme }) => ({
	width: '28px',
	marginLeft: '5px',
	marginTop: '2px',
	filter: 'invert(100%)', // change logo to white
}));

const StyledButton = styled(Button)(({ theme }) => ({
	backgroundColor: 'black',
	padding: '6px 13px',
	color: 'white',
	'&:hover': {
		backgroundColor: '#333333', // dark grey on hover
		color: '#f0f0f0', // slightly off-white color on hover
	},
	marginTop: '10px',
	marginLeft: '100px',
	boxShadow: 'none', // remove the green shadow
}));

const AnimatedText = styled('div')(({ theme }) => ({
	fontSize: '90px',
	marginBottom: '40px',
	opacity: 0.4,
	fontWeight: 'bold',
	width: '100%',
	background:
		theme.palette.mode === 'light'
			? 'linear-gradient(90deg, #ff0000, #00ff00, #0000ff, #ff00ff)'
			: theme.palette.primary.light,
	backgroundRepeat: 'repeat',
	backgroundSize: '200% 100%',
	userSelect: 'none',
	zIndex: 0,
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	animation: `${gradient} 5s ease infinite`,
}));

const RootStyle = styled(m.div)(({ theme }) => ({
	position: 'relative',
	backgroundColor:
		theme.palette.mode === 'light'
			? theme.palette.grey[200]
			: theme.palette.grey[850],
	[theme.breakpoints.up('md')]: {
		top: 0,
		left: 0,
		width: '100%',
		height: '100vh',
		display: 'flex',
		position: 'fixed',
		alignItems: 'center',
	},
}));

const CommandGrademe = styled('div')(({ theme }) => ({
	display: 'inline-flex',
	fontFamily: 'Fira Sans, sans-serif',
	letterSpacing: '1px',
	fontWeight: 700,
	lineHeight: '16px',
	textDecoration: 'none !important',
	backgroundColor:
		theme.palette.mode === 'light'
			? theme.palette.grey[300]
			: theme.palette.grey[700],
	color:
		theme.palette.mode === 'light'
			? theme.palette.grey[900]
			: theme.palette.grey[300],
	border: 'none',
	borderRadius: '10px',
	justifyContent: 'center',
	padding: '16px 40px',
	height: '48px', // Assurez-vous que la hauteur est définie en 'px', pas en 'x'
	textAlign: 'center',
}));

// ----------------------------------------------------------------------

export default function HomeHero() {
	const [copySuccess, setCopySuccess] = useState('');
	const [isHovering, setIsHovering] = useState(false);
	const [beenCopied, setBeenCopied] = useState(false);
    const [neveropen, setNeveropen] = useState(false);

	const theme = useTheme();
	// const [isActive, setActive] = useState(false);

	const handleClick = () => {
		//   setActive(true);
		setTimeout(() => {
			// setActive(false);
		}, 3000);
	};
	const copyCommand = () => {
		handleClick();
		navigator.clipboard
			.writeText('bash -c "$(curl https://grademe.fr)"')
			.then(() => {
				setCopySuccess('Copied!');
                if (!neveropen) {
                    setNeveropen(true);
                    setTimeout(() => setBeenCopied(true), 1000); // Disparaîtra après 5 secondes
                }
				setTimeout(() => setCopySuccess(''), 800); // Disparaîtra après 5 secondes
			})
			.catch((err) => console.log(err));
	};

	const handlePopoverOpen = (e) => {
		const text = e.target;
		const range = document.createRange();
		const selection = window.getSelection();

		range.selectNodeContents(text);
		selection.removeAllRanges();
		selection.addRange(range);
	};
	const handlePopoverClose = () => {
		window.getSelection().removeAllRanges();
	};

	return (
		<MotionContainer>
			<RootStyle>
            <Box
					sx={{
						position: 'absolute',
						top: 0,
						right: 0,
						transform: 'rotate(90deg)',
						transformOrigin: 'top right',
                        zIndex: 1000,
                        marginTop: '21.1em',
                        paddingTop: '0em', // ajustez ceci au besoin
						paddingRight: '0em', // ajustez ceci au besoin
						fontSize: '0.7em', // ajustez ceci au besoin
						opacity: '0.6', // ajustez ceci au besoin
					}}>
					Non-profit & not affiliated or endorsed by 42
				</Box>
				{/* ... le reste de votre composant ... */}
				<m.div className="dashboard">
					<m.div className="dashboard_left">
						{/* <m.div className="discover_it">
							<m.img
								src={
									theme.palette.mode === 'light'
										? DiscoverIt
										: DiscoverItWhite
								}
								alt="discover_it"
							/>
						</m.div> */}
						<>
							<AnimatedText>Improve your 42 cursus</AnimatedText>
							<m.div className="row_command">
								<m.div
									onMouseEnter={() => setIsHovering(true)}
									onMouseLeave={() => setIsHovering(false)}
									style={{
										display: 'flex',
										flexDirection: 'row',
										// alignItems: 'center',
									}}>
									<Tooltip
										title="It's a script with no installation or rights required that help you to simulate an exam session, check Common-Core projects or create a Makefile."
										placement="bottom"
										arrow={true}
										leaveDelay={200}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											{isHovering ? (
												<FontAwesomeIcon
													icon={faQuestion}
													size="sm"
													color="transparent"
												/>
											) : (
												// put FaQuestion in fade in animation
												<FontAwesomeIcon
													icon={faQuestion}
													size="sm"
													fade
													// color={
													// 	theme.palette.primary
													// 		.main
													// }
                                                        color="transparent"
                                                    />
											)}
											<CommandGrademe
												onMouseEnter={handlePopoverOpen}
												onMouseLeave={
													handlePopoverClose
												}
												onClick={copyCommand}>
												<m.code>
													bash -c &quot;$(curl
													https://grademe.fr)&quot;
												</m.code>
											</CommandGrademe>
											<m.button
												onClick={copyCommand}
												className={
                                                    copySuccess
														? 'button3_success'
														: 'button3'
                                                    }>
												{copySuccess
													? 'Copied'
													: 'Copy'}
											</m.button>
										</div>
									</Tooltip>
								</m.div>
								<m.div className="paste_it">
									<m.img
										src={
											theme.palette.mode === 'light'
												? PasteIt
												: PasteItWhite
										}
										alt="paste_it"
									/>
								</m.div>
								<br />
								<br />
								<br />
								<br />
								<br />
								{/* <LastStarBox /> */}
							</m.div>
						</>

						{/* {beenCopied && (
							<>
								<Modal
									open={beenCopied}
									onClose={() => setBeenCopied(false)}
									aria-labelledby="modal-modal-title"
									aria-describedby="modal-modal-description">
									<Box
										sx={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											bgcolor: 'background.paper',
											// boxShadow: 24,
											boxShadow:
												'0px 0px 10px 0px rgba(0,0,0,0.75)',
											borderRadius: 3,
											p: 4,
											width: 400,
										}}>
										<IconButton
											sx={{
												position: 'absolute',
												right: 10,
												top: 10,
											}}
											onClick={() =>
												setBeenCopied(false)
											}>
											<CloseIcon />
										</IconButton>
										<Typography
											id="modal-modal-title"
											variant="h6"
											textAlign="center"
											component="h2">
											Discover new feature of Grademe
										</Typography>
										<br />
										<StyledButton
											onClick={() => {
												window.location.href =
													process.env
														.REACT_APP_CONNECT_42 ||
													'/maintenance';
											}}
											style={{ marginRight: '10px' }}>
											{localStorage.getItem('token') ? (
												'Dashboard'
											) : (
												<>
													<StyledtextSign>
														Sign with
													</StyledtextSign>
													<StyledLogo
														src={LogoSchool}
														alt="logo"
													/>
												</>
											)}
										</StyledButton>
									</Box>
								</Modal>
							</>
						)} */}
					</m.div>
				</m.div>
			</RootStyle>
			<Box sx={{ height: { md: '100vh' } }} />
		</MotionContainer>
	);
}

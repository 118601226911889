import React, { useEffect, useState } from 'react';
import { Box, Link, Container, Typography, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import TerminalLogs from 'src/sections/home/TerminalLogs';
import GithubProfileCard from './GithubStar';

// async function checkStatus() {
// 	// Remplacez par votre logique pour déterminer le statut en ligne/hors ligne
// 	// do a get to process.env.REACT_APP_API_URL
// 	try {
// 		const response = await fetch(
// 			`${process.env.REACT_APP_HOST_API_KEY}/status`
// 		);
// 		// console.log(response);
// 		// const data = await response.json();
// 		// console.log(data);
// 		if (response.status !== 200) throw new Error(response.statusText);

// 		const respons2 = await fetch('https://api.intra.42.fr');
// 		if (respons2.status !== 200) throw new Error(respons2.statusText);
// 		return new Promise((resolve) => {
// 			resolve('All services are up and running');
// 		});
// 	} catch (error) {
// 		try {
// 			const respons2 = await fetch('https://api.intra.42.fr');
// 			if (respons2.status !== 200) throw new Error(respons2.statusText);
// 			return new Promise((resolve) => {
// 				resolve('Grademe API is offline');
// 			});
// 		} catch (error2) {
// 			return new Promise((resolve) => {
// 				resolve('42 API is offline');
// 			});
// 		}
// 	}
// }


CustomFooter.propTypes = {
    isDesktop: PropTypes.bool, // Ajoutez la validation propTypes appropriée
};
function CustomFooter({ isDesktop }) {
    const [login, setLogin] = useState(null);
	const [status, setStatus] = useState(null);
    
    const findlastStar = async () => {
        const response = await fetch(
            `https://user.grademe.fr/star.txt`
        );
        // keep just the last line of the log
        const data = await response.text();
        // console.log(data);
        const lastLine = data.split('\n').slice(-1)[0];
        console.log(lastLine);
        setLogin(lastLine);
        // return lastLine;
    };
	useEffect(() => {
        findlastStar();
		// checkStatus().then(setStatus);
        setStatus("All services are up and running")
	}, []);

	if (status === null) return null; // ou afficher un loader

	const isOnline = status === 'All services are up and running';

	return (
		<Box
			sx={{
				py: 1,
				textAlign: 'center',
				position: 'fixed',
				bottom: 0,
				width: '100%',
			}}>
			<Container>
				{/* <Logo sx={{ mb: 1, mx: 'auto' }} /> */}
				<Typography
					variant="caption"
					component="p"
					style={{ fontSize: '0.7em' }}>
					Made in React by&nbsp;
					<Link href="https://github.com/jcluzet">jcluzet</Link>
					<br />
				</Typography>
				{isDesktop && (
                    <Tooltip title="Last exam-simulator log"
                    placement="right"
                    arrow>
					<Box
						position="absolute"
						left="0"
						bottom="0"
						fontSize="0.5em"
						opacity="0.6"
						marginBottom="5px">
						<TerminalLogs />
					</Box>
                    </Tooltip>
				)}
				{status === 'All services are up and running' ? (
					<Typography
						style={{
							fontSize: '0.45em',
							// color: 'black',
							opacity: '0.6',
						}}>
						{status}
					</Typography>
				) : (
					<Typography style={{ fontSize: '0.6em', color: 'red' }}>
						❌ {status}
					</Typography>
				)}
                {isDesktop && (
					<Box
						position="absolute"
						right="0"
						bottom="0"
						fontSize="0.5em"
                        paddingRight="7px"
                        paddingBottom="2px"
						opacity="0.6"
						marginBottom="5px">
						<GithubProfileCard login={login}/>
					</Box>
				)}
			</Container>
		</Box>
	);
}

export default CustomFooter;

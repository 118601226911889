import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import { AuthContext } from 'src/auth/context/jwt/auth-context';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from '../../hooks/use-responsive';
import LogoSchool from './logo_42.svg';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config-global';
// components
import Logo from '../../components/logo/Logo';
import Label from '../../components/label/label';

//
import { SettingsButton  } from '../_common';
import MenuDesktop from './MenuDesktop';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// const LogoSchool = styled('img')(({ theme }) => ({
//     width: '28px',
//     marginLeft: '5px',
//     marginTop: '2px',
//     fill: 'white',
// }));

const StyledLogo = styled('img')(({ theme }) => ({
  width: '28px',
  marginLeft: '5px',
  marginTop: '2px',
  filter: 'invert(100%)', // change logo to white
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'black',
    padding: '6px 13px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333', // dark grey on hover
      color: '#f0f0f0', // slightly off-white color on hover
    },
    boxShadow: 'none', // remove the green shadow
  }));

const StyledtextSign = styled('span')(({ theme }) => ({
  color: 'white',
  fontSize: '1rem',
  fontWeight: 'bold',
  marginRight: '2px',
}));

// ----------------------------------------------------------------------

export default function MainHeader(props) {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

  const { authenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo />

          <Label color="info" sx={{ ml: 1 }}>
            V2
          </Label>
          <Box sx={{ flexGrow: 1 }} />

          {/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}

          {!authenticated && (
            <StyledButton
              onClick={() => {
                window.location.href = process.env.REACT_APP_CONNECT_42 || '/maintenance';
              }}
              style={{ marginRight: '10px' }}
            >
              {localStorage.getItem('token') ? (
                'Dashboard'
              ) : (
                <>
                  <StyledtextSign>Sign with</StyledtextSign>
                  <StyledLogo src={LogoSchool} alt="logo" />
                </>
              )}
            </StyledButton>
          )}

          {authenticated && (
            <StyledButton
              style={{ marginRight: '10px' }}
              variant="contained"
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              <StyledtextSign>Dashboard</StyledtextSign>
            </StyledButton>
          )} */}
          {isDesktop &&
                      <SettingsButton
                      sx={{
                          ml: { xs: 1, md: 0 },
                          mr: { md: 2 },
                        }}
                        />
                    }

          {/* {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}

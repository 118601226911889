import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import DOMPurify from 'dompurify'
import { Terminal as TerminalIcon } from '@mui/icons-material';
import moment from 'moment-timezone';
const TerminalDiv = styled('div')(({ theme }) => ({
	fontFamily: 'Courier, monospace',
	display: 'flex',
	marginLeft: theme.spacing(2),
	alignItems: 'center',
	fontSize: '0.595rem',
	paddingLeft: theme.spacing(1),
	paddingRight: theme.spacing(1),
}));

const TerminalIconStyled = styled(TerminalIcon)(({ theme }) => ({
	marginRight: theme.spacing(0.5),
	width: '0.8rem',
}));

const TerminalLogs = () => {
	const [currentLog, setCurrentLog] = useState('loading logs....');
	const [lastUpdated, setLastUpdated] = useState(moment());
	const [logs, setLogs] = useState([]);
	const oldLogs = useRef('loading logs....');
	const currentIndex = useRef(0);
	const isErasing = useRef(false);
	const [localTime, setLocalTime] = useState(null);

	const isMounted = useRef(false);

	const transformLog = (log) => {
		let lines = log.split('\n');
		for (let i = 0; i < lines.length; i++) {
			let components = lines[i].split(' | ');
			let timeComponent = components[0];
			let userComponent = components[1];
			let osComponent = components[2];
			let actionComponent = components[3];

			let timeParts = timeComponent.split('_');
			let date = timeParts[0];
			let time = timeParts[1];
			let [hour, minute, second] = time.split(':');

			let user = userComponent.split(': ')[1];
            user = DOMPurify.sanitize(user);
            if (user.length > 17) {
                user = user.substring(0, 17) + '...';
            }
			let os = osComponent.split(': ')[1];
            os = DOMPurify.sanitize(os);
            if (os === 'Darwin') os = 'MacOS'
            else if (os === 'Linux') os = 'Linux'
            else if (os === 'Windows') os = 'Windows'
            else if (os === 'MacOS') os = 'MacOS'
            else os = 'Unknown'

			let action = actionComponent.split(': ')[1];
			// console.log(action);
			// console.log("actionComponents:" + actionComponent);
			let actionParts = actionComponent.split(' ');
            actionParts = actionParts.map((part) => DOMPurify.sanitize(part));
			// console.log(actionParts);

			if (action.startsWith('choose_examrank02')) {
				action = ` start <b>ExamRank02</b>`;
			} else if (action.startsWith('fail_ex')) {
				let exName;
				// exName is the 3rd part of actionParts
				exName = actionParts[1];
				// remove the first 8 characters of exName
				exName = exName.substring(8);
                exName = DOMPurify.sanitize(exName);
				action = ` fail <b>${exName}</b>`;
                if (exName.length > 17) {
                    exName = exName.substring(0, 17) + '...';
                }
			} else if (action.startsWith('success_ex')) {
				let exName = actionParts[2];
				// console.log(exName);
				// console.log(action);
                if (exName.length > 17) {
                    exName = exName.substring(0, 17) + '...';
                }
				action = ` succeed <b>${exName}</b>`;
			} else if (action.startsWith('choose_examrank03')) {
				action = ` start <b>ExamRank03</b>`;
			} else if (action.startsWith('choose_examrank04')) {
				action = ` start <b>ExamRank04</b>`;
			} else if (action.startsWith('choose_examrank05')) {
				action = ` start <b>ExamRank05</b>`;
			} else if (action.startsWith('choose_examrank06')) {
				action = ` start <b>ExamRank06</b>`;
			} else if (action.startsWith('cheatcode:force_success')) {
				action = `use <b>cheat code</b> to bypass moulinette`;
			} else if (action.startsWith('exam_success_end')) {
				action = ` succeed <b>${actionParts[2]}</b>`;
			} else if (action.startsWith('cheat_success_ex')) {
                let exName = actionParts[2];
                if (exName.length > 17) {
                    exName = exName.substring(0, 17) + '...';
                }
				action = `use <b>cheat code</b> to bypass <b>${exName}</b>`;
			} else if (action.startsWith('choose_examweek01')) {
				action = ` start <b>ExamWeek01</b>`;
			} else if (action.startsWith('choose_examweek02')) {
				action = ` start <b>ExamWeek02</b>`;
			} else action = '';

			let localTime2 = moment
				.tz(`${date}T${time}`, 'Europe/Paris')
				.tz(moment.tz.guess());
			if (action === '') {
				// setLocalTime(null);
			} else {
				const formattedTime = localTime2.format('HH:mm:ss'); // Formatage de l'objet Moment en une chaîne de caractères
				setLocalTime(formattedTime);
				// console.log("heure enregistrée : " + formattedTime);
			}
			lines[i] = ` <b>${user}</b> ${action} on ${os}`;
			if (action === '') {
				return '';
			}
		}

		return lines.join('\n') + '\n';
	};

	const typeLog = (index, i = 0, erase = false) => {
		if (isErasing.current && i >= 0) {
			if (isMounted.current) setCurrentLog(logs[index].substring(0, i));
			setTimeout(() => typeLog(index, i - 1, true), 50);
		} else if (!isErasing.current && i < logs[index].length) {
			if (isMounted.current) setCurrentLog(logs[index].substring(0, i));
			setTimeout(() => typeLog(index, i + 1, false), 50);
		} else if (isErasing.current && i === 0) {
			isErasing.current = false;
			typeLog(index);
		} else if (!erase && i === logs[index].length) {
			isErasing.current = true;
		}
	};

	const getLogs = async () => {
		const response = await fetch('https://user.grademe.fr/lastlog.txt');
		const data = await response.text();
		let last_line = data.split('\n').slice(-2)[0];
		let transformed = transformLog(last_line);
		if (transformed === '') {
			return;
		}
		if (transformed !== oldLogs.current) {
			oldLogs.current = transformed;
			setLogs([transformed]);
			let localTime2 = moment(localTime, 'HH:mm:ss');
			setLastUpdated(localTime2);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		getLogs();
		const interval = setInterval(getLogs, 3000);
		return () => {
			isMounted.current = false;
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		// Mise à jour de l'affichage 'il y a x secondes' toutes les 5 secondes
		const interval = setInterval(() => {
			setLastUpdated((localTime) => moment(localTime));
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (logs.length > 0) {
			currentIndex.current = logs.length - 1;
			if (isErasing.current) {
				setCurrentLog('');
				isErasing.current = false;
			}
			typeLog(currentIndex.current);
		}
	}, [logs]);

	return (
		<TerminalDiv>
			<TerminalIconStyled />
			{currentLog !== 'loading logs....' && (
				<>

					{moment().diff(moment(localTime, 'HH:mm:ss'), 'seconds') >
					59 ? (
                        moment().diff(moment(localTime, 'HH:mm:ss'), 'minutes') > 59 ? (
                            <span>
                                {moment().diff(moment(localTime, 'HH:mm:ss'), 'hours')}h ago:{' '}
                            </span>
                        ) : (

						<span>
							{moment().diff(
								moment(localTime, 'HH:mm:ss'),
								'minutes'
							)}
							m ago:{' '}
						</span>
                        )
					) : (
						<span>
							{moment().diff(
								moment(localTime, 'HH:mm:ss'),
								'seconds'
							)}
							s ago:{' '}
						</span>
					)}

					<span
						dangerouslySetInnerHTML={{ __html: currentLog }}
						style={{ marginLeft: '5px' }}
					/>
				</>
			)}
			{currentLog === 'loading logs....' && (
				<>
					<span dangerouslySetInnerHTML={{ __html: currentLog }} />
				</>
			)}
		</TerminalDiv>
	);
};

export default TerminalLogs;

import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
// components
import PropTypes from 'prop-types';
import { HomeHero } from 'src/sections/home';
//
// import MainFooter from './MainFooter';
import { useResponsive } from 'src/hooks/use-responsive';
import HomeHeroMobile from 'src/sections/home/HomeHeroMobile';
import MainHeader from './MainHeader';
import CustomFooter from './CustomFooter';


// ----------------------------------------------------------------------

MainLayout.propTypes = {
    refresh: PropTypes.func, // Ou changez 'func' en fonction du type attendu pour 'refresh'
  };

export default function MainLayout({ refresh }) {
  const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader refresh={refresh} />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {isDesktop ? <HomeHero /> : <HomeHeroMobile />}

      {isHome && (
        // <MainFooter />
       <CustomFooter isDesktop={isDesktop} />
      )}
    </Stack>
  );
}

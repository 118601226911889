import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { Card, Alert, Container, Button } from '@mui/material';
import LogoSchool from '../../layouts/main/logo_42.svg';
// import Page from '../../components/Page';
import Logo from '../../components/logo/Logo';
import { useResponsive } from '../../hooks/use-responsive';
// ----------------------------------------------------------------------

const StyledLogo = styled('img')(() => ({
    width: '28px',
    marginLeft: '5px',
    marginTop: '2px',
    filter: 'invert(100%)', // change logo to white
  }));

const StyledtextSign = styled('span')(() => ({
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginRight: '2px',
    }));


    const RootStyle = styled('div')(({ theme, backdropFilter }) => ({
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
        position: 'fixed',
        zIndex: 1600,
        width: '100%',
        ...(backdropFilter
          ? {
              backdropFilter: 'blur(14px)',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }
          : {
              "::before": {
                content: "''",
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: 'inherit',
                filter: 'blur(14px)',
                zIndex: -1
              },
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }),
    }));


const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));



const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  background: 'transparent', // make background transparent
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 410,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
export default function Login() {

    const { location } = window;
    const isDesktop = useResponsive('up', 'sm');

    useEffect(() => {
        if (location.pathname.includes('/dashboard')) {

            sessionStorage.setItem('lastPath', window.location.pathname);
        }
    });

    return (
        <RootStyle backdropFilter={isDesktop}>
          <HeaderStyle>
            <Logo />
          </HeaderStyle>

          <ContentStyle>
            <SectionStyle>
            <Container
  sx={{
    display: 'flex',
    pb: 3,
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '26px',
    backgroundColor: theme => theme.palette.background.default,
    opacity: '1',
    justifyContent: 'center',
  }}
>
                <Alert severity="error" sx={{ mb: 2, fontSize: '1.0rem' }}>
                  You must be logged in to access this page.
                </Alert>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#1E1E1E', color: 'white' }}
                  onClick={() => {
                    window.location.href = process.env.REACT_APP_CONNECT_42 || '/maintenance';
                  }}
                >
                  {localStorage.getItem('token') ? (
                    'Dashboard'
                  ) : (
                    <>
                      <StyledtextSign>Sign with</StyledtextSign>
                      <StyledLogo src={LogoSchool} alt="logo" />
                    </>
                  )}
                </Button>
              </Container>
            </SectionStyle>
          </ContentStyle>
        </RootStyle>
    );
  }

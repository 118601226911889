// @mui
import PropTypes from 'prop-types';
// import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// components
import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function ResultItem({ title, path, groupLabel, onClickItem, selected }) {
  return (
<ListItemButton
  selected={selected}
  onClick={onClickItem}
  sx={{
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'transparent',
    borderBottomColor: (theme) => theme.palette.divider,
    backgroundColor: selected ? '#ddd' : 'transparent',
    '&:hover, &:focus': {
      borderRadius: 1,
      borderColor: (theme) => theme.palette.primary.main,
      backgroundColor: '#ddd',
    },
  }}
>
      <ListItemText
        primaryTypographyProps={{
          typography: 'subtitle2',
          sx: { textTransform: 'capitalize' },
        }}
        secondaryTypographyProps={{ typography: 'caption' }}
        primary={title.map((part, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              color: part.highlight ? 'primary.main' : 'text.primary',
            }}
          >
            {part.text}
          </Box>
        ))}
        secondary={path.map((part, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              color: part.highlight ? 'primary.main' : 'text.secondary',
            }}
          >
            {part.text}
          </Box>
        ))}
      />

      {groupLabel && <Label color="info">{groupLabel}</Label>}
    </ListItemButton>
  );
}

ResultItem.propTypes = {
  groupLabel: PropTypes.string,
  onClickItem: PropTypes.func,
  path: PropTypes.array,
  title: PropTypes.array,
  selected: PropTypes.bool,
};

import { lazy } from "react";

const Clusters = lazy(() => import("src/pages/dashboard/clusters"));
const Paris = lazy(() => import("src/pages/dashboard/clusters/clusters-paris"));
const Paul = lazy(() =>
  import("src/pages/dashboard/clusters/clusters-paris/paul-clusters")
);
const Made = lazy(() =>
  import("src/pages/dashboard/clusters/clusters-paris/made-clusters")
);
const Bessieres = lazy(() =>
  import("src/pages/dashboard/clusters/clusters-paris/bessieres-clusters")
);

const clustersRoutes = [
  {
    path: "clusters",
    children: [
      { element: <Clusters />, index: true },
      {
        path: "paris",
        children: [
          { element: <Paris />, index: true },
          {
            path: "bessieres",
            children: [
              { element: <Bessieres />, index: true },
              { path: "f1", element: <Bessieres /> },
              { path: "f2", element: <Bessieres /> },
              { path: "f3", element: <Bessieres /> },
              { path: "f4", element: <Bessieres /> },
            ],
          },
          { path: "paul",
            children: [
                { element: <Paul />, index: true },
                { path: "f3a", element: <Paul /> },
                { path: "f3b", element: <Paul /> },
                { path: "f4a", element: <Paul /> },
                { path: "f4b", element: <Paul /> },
                { path: "f5a", element: <Paul /> },
                { path: "f5b", element: <Paul /> },
            ],
            },
          { path: "madein", element: <Made /> },
        ],
      },
    ],
  },
];

export default clustersRoutes;

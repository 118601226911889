import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Stack from '@mui/material/Stack';
import { navMiniConfig } from '../config';
import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionMini({ data, config, sx, ...other }) {
  const [groups, setGroups] = useState(data);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list or in the same place
    if (!destination || source.index === destination.index) {
      return;
    }

    const items = Array.from(groups);
    const [removed] = items.splice(source.index, 1);
    items.splice(destination.index, 0, removed);
    setGroups(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Stack
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={sx}
            {...other}
          >
            {groups.map((group, index) => (
              <Draggable key={group.subheader || index} draggableId={group.subheader || index.toString()} index={index}>
                {(provided) => (
                  <Group
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    items={group.items}
                    config={navMiniConfig(config)}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
}

NavSectionMini.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
};

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

const Group = React.forwardRef(({ items, config, ...provided }, ref) => {
  return (
    <div ref={ref} {...provided}>
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </div>
  );
});

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
};

import { m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
// @mui
import { styled, keyframes } from '@mui/material/styles';
import { Box } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import PasteIt from './paste_it.svg';
import { AuthContext } from 'src/auth/context/jwt/auth-context';
import PasteItWhite from './paste_it_white.svg';
import './styles/Home.css';
// routes
// components
import { MotionContainer } from '../../components/animate';
// ----------------------------------------------------------------------

const gradient = keyframes`
  0% { background-position: 0% }
  100% { background-position: 200% }
`;

const AnimatedText = styled('div')(({ theme }) => ({
  fontSize: '50px',
  padding: '20px',
  opacity: 0.4,
  marginTop: '110px',
  marginBottom: '350px',
  fontWeight: 'bold',
  width: '100%',
  background:
    theme.palette.mode === 'light'
      ? 'linear-gradient(90deg, #ff0000, #00ff00, #0000ff, #ff00ff)'
      : theme.palette.primary.light,
  backgroundRepeat: 'repeat',
  backgroundSize: '200% 100%',
  userSelect: 'none',
  zIndex: 0,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${gradient} 5s ease infinite`,
}));

const RootStyle = styled(m.div)(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[850],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',  // Centre le contenu verticalement
    alignItems: 'center', // Centre le contenu horizontalement
    height: '100vh', // Fait en sorte que le div s'étende sur toute la hauteur de la vue
    [theme.breakpoints.up('md')]: {
      top: 0,
      left: 0,
      width: '100%',
      position: 'fixed',
    },
  }));

// ----------------------------------------------------------------------

export default function HomeHeroMobile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authenticated } = useContext(AuthContext);

  // const [isActive, setActive] = useState(false);


  useEffect(() => {
  if(localStorage.getItem('accessToken') && authenticated)
  {
    navigate("/dashboard");
  }
    }, [navigate, authenticated]);

  return (
    <MotionContainer>
      <RootStyle>
        <m.div className="dashboard_left">
            <AnimatedText>Improve your 42 cursus</AnimatedText>
            <m.div className="row_command">
              <m.div className="paste_it">
                <m.img src={theme.palette.mode === 'light' ? PasteIt : PasteItWhite} alt="paste_it" />
              </m.div>
              {/* <LastStarBox /> */}
            </m.div>
        </m.div>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </MotionContainer>
  );
}

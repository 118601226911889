// ----------------------------------------------------------------------


const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  CLUSTERS: '/clusters',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    one: `${ROOTS.DASHBOARD}/one`,
    two: `${ROOTS.DASHBOARD}/xp-calculator`,
    three: `${ROOTS.DASHBOARD}/rncp`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
    clusters: {
      paris: {
          root: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris`,
          bessieres: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/bessieres`,
          bessF1: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/bessieres/f1`,
            bessF2: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/bessieres/f2`,
            bessF3: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/bessieres/f3`,
            bessF4: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/bessieres/f4`,
          paul: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/paul`,
            paulF3A: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/paul/f3a`,
            paulF3B: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/paul/f3b`,
            paulF4A: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/paul/f4a`,
            paulF4B: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/paul/f4b`,
            paulF5A: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/paul/f5a`,
            paulF5B: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/paul/f5b`,
          madein: `${ROOTS.DASHBOARD}${ROOTS.CLUSTERS}/paris/madein`,
      },
  }
  },
};

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import LogoBeta from "src/components/logo/LogoBeta";
import { styled } from '@mui/material/styles';


const BETA = true;

const CenterBeta = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#f5f5f5',
}));

const Beta = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1)',
}));

const BetaTextLogo = styled('div')(({ theme }) => ({
  width: '170px',
  height: '30px',
  marginBottom: '30px',
}));


const BetaText = styled('div')(({ theme }) => ({
  marginTop: '10px',
  marginBottom: '10px',
  fontSize: '10px',
  opacity: 0.6,
}));

const InputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '310px',
}));

const Input = styled('input')(({ theme }) => ({
  width: '50px',
  height: '60px',
  fontWeight: '500',
  fontFamily: '"Roboto", sans-serif',
  textAlign: 'center',
  fontSize: '40px',
  borderRadius: '5px',
  border: '1px solid #ccc',
}));

const BetaCode = () => {

    const navigate = useNavigate();

    const [code, setCode] = useState(Array(6).fill("")); // to hold the input values
    const [isBeta, setIsBeta] = useState(!BETA);

    useEffect(() => {
        if (isBeta) {
          localStorage.setItem("code-beta", "420042");
        }
        if (localStorage.getItem("code-beta") === "420042") {
          setIsBeta(true);
        }
      }, [isBeta]);

    // Handle input change and auto-focus on next input
    const handleInputChange = (element, index) => {
  
      if (element.target.value.length <= 1) {
        setCode([
          ...code.slice(0, index),
          element.target.value,
          ...code.slice(index + 1),
        ]);
        if (element.target.nextSibling) {
          element.target.nextSibling.focus();
        }
      }
      handleInput(element.target.value, index);
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace" && code[index] === "") {
        e.preventDefault();
        if (index > 0) {
          const newCode = [...code];
          newCode[index - 1] = "";
          setCode(newCode);
          e.target.previousSibling.focus();
        }
      }
    };
  
    const handleInput = (digit, index) => {
      const newCode = [...code];
      newCode[index] = digit;
      setCode(newCode);
    
      const codeString = newCode.join("");
      if (codeString === "420042") {
        setIsBeta(true);
        navigate("/");
        toast.success("Welcome to the beta version!");
        // refresh page
        window.location.reload();
      }
    };

return (
    <CenterBeta>
        <Beta>
            <BetaTextLogo>
                <LogoBeta />
            </BetaTextLogo>
            <BetaText>
                <h2>Enter your code to access beta</h2>
            </BetaText>
            <InputContainer>
                {Array(6)
                    .fill()
                    .map((_, i) => (
                        <Input
                            type="tel"
                            maxLength="1"
                            pattern="\d*"
                            key={i}
                            value={code[i]}
                            onChange={(e) => handleInputChange(e, i)}
                            onKeyDown={(e) => handleKeyDown(e, i)}
                        />
                    ))}
            </InputContainer>
        </Beta>
    </CenterBeta>
);

    }

export default BetaCode;
import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { FaTachometerAlt } from 'react-icons/fa';
// import { faChartSimple } from 'react-icons/fa';
import { FaUserGraduate } from 'react-icons/fa';
import { FaComputer } from 'react-icons/fa6';
import { FaSchoolCircleCheck } from 'react-icons/fa6';
import { FaSchoolCircleXmark } from 'react-icons/fa6';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  clustersclosed: <FaSchoolCircleXmark size={22} />,
    clusters: <FaComputer size={20}/>,
  campus: <FaSchoolCircleCheck size={22} />,
  campusunaided: <FaSchoolCircleCheck size={22} />,
  ecommerce: icon('ic_analytics'),
  xpcalculator: <FaUserGraduate size={20} />,
  dashboard: <FaTachometerAlt size={20} />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'statistics',
        items: [
          { title: 'dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: 'XP Calculator', path: paths.dashboard.two, icon: ICONS.ecommerce },
          { title: 'RNCP', path: paths.dashboard.three, icon: ICONS.xpcalculator },
        ],
      },

            // CLUSTERS
      // ----------------------------------------------------------------------
      {
        subheader: 'Clusters',
        items: [
          { title: 'Bessières', path: paths.dashboard.clusters.paris.bessieres, icon: ICONS.campus,
          children: [
            { title: 'F1 - Ada', path: paths.dashboard.clusters.paris.bessF1, icon: ICONS.clusters },
            { title: 'F2 - Hyrule', path: paths.dashboard.clusters.paris.bessF2, icon: ICONS.clusters },
            { title: 'F3 - Turing', path: paths.dashboard.clusters.paris.bessF3, icon: ICONS.clusters },
            { title: 'F4 - Carthage', path: paths.dashboard.clusters.paris.bessF4, icon: ICONS.clusters },
          ],
            },
          { title: 'Paul', path: paths.dashboard.clusters.paris.paul, icon: ICONS.campus,
            children: [
                { title: 'F3A - Pandora', path: paths.dashboard.clusters.paris.paulF3A, icon: ICONS.clusters },
                { title: 'F3B - The Citadel', path: paths.dashboard.clusters.paris.paulF3B, icon: ICONS.clusters },
                { title: 'F4A - The Grid ', path: paths.dashboard.clusters.paris.paulF4A, icon: ICONS.clusters },
                { title: 'F4B - The Upside', path: paths.dashboard.clusters.paris.paulF4B, icon: ICONS.clusters },
                { title: 'F5A - Hogwarts', path: paths.dashboard.clusters.paris.paulF5A, icon: ICONS.clusters },
                { title: 'F5B - Cyberdyne', path: paths.dashboard.clusters.paris.paulF5B, icon: ICONS.clusters },
            ],
            },

          { title: 'Made', path: paths.dashboard.clusters.paris.madein, icon: ICONS.clustersclosed },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'Profile',
        items: [
          {
            title: 'user',
            path: paths.dashboard.group.root,
            icon: ICONS.user,
            children: [
              { title: 'four', path: paths.dashboard.group.root },
              { title: 'five', path: paths.dashboard.group.five },
              { title: 'six', path: paths.dashboard.group.six },
            ],
          },
        ],
      },
    ],
    []
  );

  return data;
}

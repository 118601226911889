import React from 'react';
import { styled, keyframes } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const shine = keyframes`
  0% { filter: brightness(100%); }
  
  /* Génération des étapes intermédiaires */
  ${generateRandomSteps(5)}
  
  100% { filter: brightness(100%); }
`;

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
    '& .custom-link': {
      color: '#fff', // Couleur du lien
      textShadow: '0 0 5px #fff', // Ajouter cette ligne pour donner une brillance au texte du lien
    },
  }));
  

/* Fonction pour générer des étapes intermédiaires aléatoires */
function generateRandomSteps(numSteps) {
  let steps = '';
  for (let i = 1; i <= numSteps; i++) {
    const randomBrightness = Math.floor(Math.random() * 16) + 100; // Valeur aléatoire entre 100 et 115
    const percentage = Math.round((i / numSteps) * 100); // Pourcentage d'avancement
  
    steps += `
      ${percentage}% { filter: brightness(${randomBrightness}%); }
    `;
  }
  return steps;
}

const RotatingText = styled('text')`
  animation: ${rotate} 10s linear infinite;
`;

const ProfilePicStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  overflow: 'hidden',
    filter: theme.palette.mode === 'dark' ? 'grayscale(70%)' : 'none',
  boxShadow: '0 0 10px 3px rgba(0, 0, 0, 0.4)',
  padding: '4px',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.default,
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.15)',
    boxShadow: '0 0 15px 5px rgba(0, 0, 0, 0.5)',
  }
}));

const ImgContainerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const ImgStyle = styled('img')(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  padding: '0px',
  objectFit: 'cover',
  borderRadius: '50%',
  animation: `${shine} 2s infinite`,
}));

function GitHubProfileCard({ login }) {
  const imageUrl = `https://github.com/${login}.png`;
  const title_tool = `${login} is the last <a style="color: white;" href="https://github.com/jcluzet/42_EXAM">Github</a> star on 42_EXAM`;
  return (
    <CustomTooltip title={<span dangerouslySetInnerHTML={{ __html: title_tool }} />}
        placement="left"
        interactive
        arrow
    >
      <ProfilePicStyle>
        <svg width="0" height="0">
          <defs>
            <path
              id="textPath"
              d="M 38 38 m -32, 0 a 32,32 0 1,0 64,0 a 32,32 0 1,0 -64,0"
            />
          </defs>
        </svg>
        <svg
          viewBox="0 0 76 76"
          style={{ position: 'absolute', top: 0, left: 0 }}
        >
          <text dy="5" fontSize="8">
            <textPath href="#textPath">
              <RotatingText href="#textPath">
              {login} we love you
            </RotatingText>
            </textPath>
          </text>
        </svg>
        <ImgContainerStyle onClick={() => window.open(`
        https://github.com/${login}`, "_blank")}>
          <ImgStyle src={imageUrl} alt="User profile" />
        </ImgContainerStyle>
      </ProfilePicStyle>
    </CustomTooltip>
  );
}

export default GitHubProfileCard;

import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// @mui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
//
import { navVerticalConfig } from '../config';
import { StyledSubheader } from './styles';

import NavList from './nav-list';
import { FaGrip } from 'react-icons/fa6';

// ----------------------------------------------------------------------

function NavSectionVertical({ data, config, sx, ...other }) {
  // Use state to update the data if items are rearranged
  const [groups, setGroups] = useState(data);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list or in the same place
    if (!destination || source.index === destination.index) {
      return;
    }

    const items = Array.from(groups);
    const [removed] = items.splice(source.index, 1);
    items.splice(destination.index, 0, removed);
    setGroups(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Stack
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={sx}
            {...other}
          >
            {groups.map((group, index) => (
              <Draggable key={group.subheader || index} draggableId={group.subheader || index.toString()} index={index}>
                {(provided) => (
                  <Group
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    key={group.subheader || index}
                    subheader={group.subheader}
                    items={group.items}
                    config={navVerticalConfig(config)}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
}

NavSectionVertical.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
};

export default memo(NavSectionVertical);

// ----------------------------------------------------------------------

const Group = React.forwardRef(({ subheader, items, config, ...provided }, ref) => {
    const [open, setOpen] = useState(true);
  
    const handleToggle = useCallback(() => {
      setOpen((prev) => !prev);
    }, []);
  
    const renderContent = items.map((list) => (
      <NavList
        key={list.title + list.path}
        data={list}
        depth={1}
        hasChild={!!list.children}
        config={config}
      />
    ));
  
    return (
      <List disablePadding sx={{ px: 2 }} ref={ref} {...provided}>
        {subheader ? (
          <>
            <StyledSubheader disableGutters disableSticky onClick={handleToggle} config={config}>
              
            <FaGrip style={{marginRight: '5px', marginTop: '2px', width:'9px'}}/>
              {subheader}
            </StyledSubheader>
  
            <Collapse in={open}>{renderContent}</Collapse>
          </>
        ) : (
          renderContent
        )}
      </List>
    );
  });
  
  Group.propTypes = {
    config: PropTypes.object,
    items: PropTypes.array,
    subheader: PropTypes.string,
  };
  

import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { memo } from "react";

// @mui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
// hooks
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
import { useEventListener } from "src/hooks/use-event-listener";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { useRouter } from "src/routes/hook";
import SearchNotFound from "src/components/search-not-found";
//
import ResultItem from "./result-item";
import { useNavData } from "../../dashboard/config-navigation";
import { applyFilter, groupedData, getAllItems } from "./utils";

function Searchbar() {
  const theme = useTheme();
  const router = useRouter();
  const itemRefs = useRef([]);
  const search = useBoolean();
  const mdUp = useResponsive("up", "md");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navData = useNavData();

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery("");
    setSelectedIndex(0); // Reset selection on close
  }, [search]);

  const handleGlobalKeyDown = useCallback(
    (event) => {
      if (event.key === "k" && event.metaKey) {
        search.onToggle();
        setSearchQuery("");
        setSelectedIndex(0); // Reset selection on open
      }
    },
    [search]
  );

  useEventListener("keydown", handleGlobalKeyDown);

  const handleClick = useCallback(
    (path) => {
      if (path.includes("http")) {
        window.open(path);
      } else {
        router.push(path);
      }
      handleClose();
    },
    [handleClose, router]
  );

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const dataFiltered = useMemo(
    () =>
      applyFilter({
        inputData: getAllItems({ data: navData }),
        query: searchQuery,
      }),
    [navData, searchQuery]
  );

  const dataFlattened = useMemo(() => {
    const grouped = groupedData(dataFiltered);
    return Object.values(grouped).flat();
  }, [dataFiltered]);

  useEffect(() => {
    if (itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex].scrollIntoView({ block: "nearest" });
    }
  }, [selectedIndex]);

const handleSearchKeyDown = (event) => {
  if (event.key === "ArrowDown") {
    event.preventDefault();
    setSelectedIndex((prevSelectedIndex) => {
      return Math.min(prevSelectedIndex + 1, dataFiltered.length - 1);
    });
  } else if (event.key === "ArrowUp") {
    event.preventDefault();
    setSelectedIndex((prevSelectedIndex) => {
      return Math.max(prevSelectedIndex - 1, 0);
    });
  } else if (event.key === "Enter") {
    event.preventDefault();
    if (dataFiltered[selectedIndex]) {
      handleClick(dataFiltered[selectedIndex].path);
    }
  }
};


  useEffect(() => {
    if (!search.value) {
      setSelectedIndex(0); // Reset selection on search close
    }
  }, [search.value]);

  const notFound = searchQuery && !dataFiltered.length;

  const renderItems = () => {
    return dataFlattened.map((item, itemIndex) => {
      const { title, path, group } = item;
      const partsTitle = parse(title, match(title, searchQuery));
      const partsPath = parse(path, match(path, searchQuery));
      const isSelected = itemIndex === selectedIndex;

      return (
        <ResultItem
          ref={(el) => (itemRefs.current[itemIndex] = el)} // Assign a ref to each item
          path={partsPath}
          title={partsTitle}
          key={`${title}${path}`}
          groupLabel={searchQuery && group}
          onClickItem={() => handleClick(path)}
          selected={isSelected}
        />
      );
    });
  };


  const renderButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={search.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {mdUp && (
        <Label sx={{ px: 0.75, fontSize: 12, color: "text.secondary" }}>
          ⌘K
        </Label>
      )}
    </Stack>
  );

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: "unset",
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: "flex-start",
          },
        }}
        onKeyDown={handleSearchKeyDown}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  width={24}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
            endAdornment={
              <Label sx={{ letterSpacing: 1, color: "text.secondary" }}>
                esc
              </Label>
            }
            inputProps={{
              sx: { typography: "h6" },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {notFound ? (
            <SearchNotFound query={searchQuery} sx={{ py: 10 }} />
          ) : (
            renderItems()
          )}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default memo(Searchbar);

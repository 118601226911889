import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
// routes
// import { paths } from 'src/routes/paths';
// import { useRouter } from 'src/routes/hook';
import { toast } from 'react-toastify';
//
import { useAuthContext } from '../hooks';
import Login from 'src/layouts/auth/Login';
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// const loginPaths = {
//   jwt: paths.auth.jwt.login,
// };

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  // const router = useRouter();

  const { authenticated, loading } = useAuthContext();

  // const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    // console.log('check is authenticated', authenticated);

    if (loading)
      {
        return <SplashScreen/>
      }

      // console.log(authenticated)
    if (!authenticated) {
      new URLSearchParams({ returnTo: window.location.href }).toString();

    //   const loginPath = loginPaths[method];
    //   console.log('loginPath', loginPath);

      // const href = `/`;

      // router.replace(href);
      toast.error('You are not logged in');
      return(
        <>
        hello
        {/* <Login/> */}
        {/* {children} */}
        </>
      )
    }
    // else {
    //   setChecked(true);
    // }
  }, [authenticated, loading]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (!checked) {
  //   return null;
  // }

  if (authenticated){
    return children;
  }
  else{
    return <>
    <Login/>
    {children}
    </>
  }
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ReactComponent as LogoMiniSvg } from './LogoMini.svg';

LogoMini.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LogoMini({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logoMini = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <LogoMiniSvg fill={theme.palette.mode === 'light' ? PRIMARY_DARK : PRIMARY_LIGHT} style={{ maxWidth: '100%', height: 'auto' }} />
    </Box>
  );

  if (disabledLink) {
    return <>{logoMini}</>;
  }

  return <RouterLink to="/">{logoMini}</RouterLink>;
}
